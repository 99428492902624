/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/layout";

class PageNotFound extends React.PureComponent {
  render() {
    return (
      <Layout location={this.props.location}>
        <Helmet>
          <title>Page Not Found</title>
        </Helmet>
        <div>Page not found</div>
      </Layout>
    );
  }
}

export default PageNotFound;
